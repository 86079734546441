.report-container {
  padding: 30px;
  border-radius: 10px;
  max-width: 1400px;
  margin: 30px auto;
  color: #d8e6f3;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #1e2235;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.report-container h2 {
  color: #00aea5;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.report-summary {
  background-color: #272b4a;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.report-summary p {
  margin: 10px 0;
  font-size: 18px;
}

.report-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 12px;
}

.report-table th {
  background-color: #272b4a;
  color: #00aea5;
  font-weight: 600;
  text-align: left;
  padding: 16px;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.report-table td {
  background-color: #2c305e;
  padding: 16px;
  border: none;
}

.report-table tr {
  transition: all 0.3s ease;
}

.report-table tr:hover td {
  background-color: #353b6e;
}

/* Styling for expired licenses */
.report-table tr.expired td {
  background-color: rgba(231, 76, 60, 0.3); /* Red with opacity */
  color: #e74c3c; /* Bright red text */
}

.report-table tr.expired:hover td {
  background-color: rgba(231, 76, 60, 0.4); /* Slightly darker red on hover */
}

.report-loading, .report-error {
  text-align: center;
  font-size: 18px;
  margin-top: 50px;
}

.report-error {
  color: #e74c3c;
}

/* Styles for Time Until Expiration */
.report-table td:last-child {
  font-weight: 600;
}

.report-table tr:not(.expired) td:last-child[data-expiration^="0 day"],
.report-table tr:not(.expired) td:last-child[data-expiration^="1 day"],
.report-table tr:not(.expired) td:last-child[data-expiration^="2 day"],
.report-table tr:not(.expired) td:last-child[data-expiration^="3 day"] {
  color: #f39c12; /* Orange for soon-to-expire licenses */
}

@media screen and (max-width: 768px) {
  .report-container {
    padding: 20px;
  }

  .report-table {
    font-size: 14px;
  }

  .report-table th,
  .report-table td {
    padding: 12px 8px;
  }
}