body {
  margin: 0;
  font-family: Arial, sans-serif;
  color: #d8e6f3;
  background-color: #1a1d2e;
}

.app {
  min-height: 100vh;
  display: flex;
}

.side-nav {
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #212446;
  padding-top: 20px;
  box-sizing: border-box;
  border-right: 2px solid #00aea5;
  display: flex;
  flex-direction: column;
}

.side-nav a,
.side-nav button {
  display: block;
  color: #E9F4F4;
  padding: 12px 20px;
  text-decoration: none;
  border-radius: 4px;
  font-size: 15px;
  border: none;
  background: none;
  width: 80%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.side-nav h1 {
  color: #00aea5;
  padding: 10px 20px;
  margin: 0 0 20px 0;
  font-size: 24px;
  border-bottom: 2px solid #00aea5;
}

.side-nav a:hover,
.side-nav button:hover {
  background-color: #2c305e;
  color: #00aea5;
  border: 2px solid #00aea5;
  width: 80%;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
}

.main-content.with-navbar {
  margin-left: 250px;
}

.logout-btn {
  margin-top: auto;
  background-color: #1a1d2e !important;
  color: #00aea5 !important;
  border: 1px solid #00aea5 !important;
  border-radius: 4px;
  padding: 10px 20px !important;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
  margin-left: 23px;
  margin-bottom: 10px;
}

.logout-btn:hover {
  background-color: #00aea5 !important;
  color: #1a1d2e !important;
  width: 100%;
}

/* log viewer */
.log-viewer {
  padding: 20px;
  background: #212446;
}

.log-container {
  background: #1a1d2e;
  padding: 10px;
  border: 1px solid #00aea5;
  max-height: 600px;
  overflow-y: auto;
  font-family: monospace;
  color: #fff;
}