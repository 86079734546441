.error {
  color: #ff4757;
  margin-bottom: 1rem;
  text-align: center;
}


.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #1a1d2e;
}

.login-form {
  background-color: #212446;
  padding: 40px;
  border-radius: 8px;
  width: 300px;
}

.login-form h2 {
  color: #00aea5;
  margin-bottom: 20px;
}

.login-form input {
  width: 93%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #00aea5;
  background-color: #1a1d2e;
  border-radius: 4px;
  color: #d8e6f3;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #00aea5;
  color: #1a1d2e;
  border: none;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #008c8c;
}