.user-list {
  padding: 30px;
  border-radius: 10px;
  max-width: 1400px;
  margin: 30px auto;
  color: #d8e6f3;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #1e2235;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.header h2 {
  margin: 0;
  color: #00aea5;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.add-user-btn {
  background-color: #00aea5;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.add-user-btn:hover {
  background-color: #00c2b7;
}

.user-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 12px;
}

.user-table th {
  background-color: #272b4a;
  color: #00aea5;
  font-weight: 600;
  text-align: left;
  padding: 16px;
  border: none;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.user-table td {
  background-color: #2c305e;
  padding: 16px;
  border: none;
}

.user-table tr {
  transition: all 0.3s ease;
}

.user-table tr:hover td {
  background-color: #353b6e;
}

.user-table tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.user-table tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.message {
  color: #e74c3c;
  margin-bottom: 15px;
  font-weight: 500;
}

.add-user-form {
  background-color: #272b4a;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-user-form input,
.add-user-form select {
  width: 95%;
  padding: 12px 16px;
  margin-bottom: 15px;
  border: 1px solid #3a3f6d;
  border-radius: 6px;
  background-color: #1e2235;
  color: #d8e6f3;
  font-size: 16px;
  transition: all 0.3s ease;
}

.add-user-form input:focus,
.add-user-form select:focus {
  outline: none;
  border-color: #00aea5;
  box-shadow: 0 0 0 2px rgba(0, 174, 165, 0.2);
}

.add-user-form button {
  background-color: #00aea5;
  color: #ffffff;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.add-user-form button:hover {
  background-color: #00c2b7;
}

.button-group {
  display: flex;
  gap: 10px;
}

.delete-btn {
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.delete-btn:hover {
  background-color: #f15a4a;
}

.delete-btn:disabled {
  background-color: #4a4d6e;
  cursor: not-allowed;
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .user-list {
    padding: 20px;
  }

  .user-table {
    font-size: 14px;
  }

  .user-table th,
  .user-table td {
    padding: 12px 8px;
  }

  .add-user-btn,
  .add-user-form button,
  .delete-btn {
    padding: 10px 16px;
    font-size: 14px;
  }
}